.bottom_footer_all_icons_container {
  grid-area: icons;
  width: 100%;
  background-color: rgb(0, 129, 177);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 1.5rem;
  padding-bottom: 2vh;
}

.bottom_footer_all_icons_container > div {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.bottom_footer_company_details {
  grid-area: details;
  text-align: center;
  color: #fff;
  padding-bottom: 2vh;
}

.bottom_footer_logo_container {
  grid-area: logo;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5rem;
  max-width: 15rem;
  width: 30vw;
  margin: 0 auto;
  height: 25%;
  background-color: rgb(0, 129, 177);
}

.bottom_footer_policy_links_container {
  grid-area: links;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bottom_footer_policy_links_container > *:nth-child(2) {
  display: none;
}

.bottom_footer_policy_links_container > * {
  width: 35%;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #fff;
  text-align: center;
}

.bottom_footer_container {
  grid-area: footer;
  width: 100%;
  height: 60vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 0.2fr);
  grid-template-areas:
    "icons icons icons"
    "links links links"
    "details details details"
    "logo logo logo";
  overflow: hidden;
  grid-gap: 1rem;
  background-color: rgb(0, 129, 177);
}

.footer_icon {
  color: white;
}

@media only screen and (min-width: 768px) {
  .bottom_footer_logo_container {
    height: 5vh;
  }

  .bottom_footer_container {
    height: 25vh;
  }

  .bottom_footer_policy_links_container > * {
    width: auto;
  }

  .bottom_footer_policy_links_container > *:nth-child(2) {
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .footer_icon {
    font-size: 3vh;
  }

  .footer_icon:hover,
  .bottom_footer_policy_links_container > a:hover {
    color: rgb(44, 44, 52);
    transition: color 0.5s ease;
  }

  .bottom_footer_logo_container {
    height: 5vh;
    opacity: 0;
  }

  .bottom_footer_all_icons_container > div {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 2000px) {
  .bottom_footer_logo_container {
    opacity: 1;
  }
}
