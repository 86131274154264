.contact_us_map_container {
  grid-area: map;
  overflow: hidden;
  height: 100%;
  max-width: 100vw;
  position: relative;
}

.contact_us_header {
  grid-area: header;
  position: relative;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact_us_header h1 {
  font-size: 3.5vh;
  font-weight: 900;
  color: rgb(44, 44, 52);
  -webkit-text-fill-color: rgb(44, 44, 52);
  line-height: 0.5rem;
  padding-bottom: 2.5vh;
  padding-top: 0.5rem;
  display: inline-block;
  white-space: nowrap;
}

.contact_us_marker_icon,
.contact_us_address > *,
.contact_us_contact_container > * {
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
}

.contact_us_address_container {
  grid-area: address;
  font-size: 3vh;
  display: flex;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 3vh;
}

.contact_us_marker_icon {
  margin-right: 0.7rem;
}

.contact_us_contact_container {
  grid-area: contact;
  font-size: 3vh;
  min-width: max-content;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.contact_us_times_section {
  grid-area: hours;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.contact_us_times_section h2 {
  font-size: 3vh;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.contact_us_availability_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 3vh;
}

.contact_us_availability_container p {
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
}

.contact_us_hours_container {
  min-width: max-content;
}

.contact_us_open_status {
  grid-area: open;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: max-content;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 3vh;
}

.contact_us_open_status h3 {
  font-size: 3vh;
  padding-bottom: 3vh;
  font-weight: 600;
}

.contact_us_open_status p {
  font-size: 3vh;
  font-weight: 400;
}

.contact_us_days_container {
  margin-right: 3vw;
}

.contact_us_page_container {
  height: 100%;
  max-width: 100vw;
  display: grid;
  grid-template-rows: 10% 35% 0.25fr 0.25fr 0.25fr 15% 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas:
    ". . header . ."
    "map map map map map"
    "address address address address address"
    "contact contact contact contact contact"
    "hours hours hours hours hours"
    "open open open open open"
    "footer footer footer footer footer";
  grid-gap: 0rem;
  padding-top: 5vh;
  background: rgb(255, 255, 255);
  position: relative;
}

.contact_us_underline {
  border-bottom: 3px solid rgb(44, 44, 52);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  transition: width 1s ease;
}

.contact_us_times_section h2,
.contact_us_availability_container,
.contact_us_open_status h3,
.contact_us_open_status p,
.contact_us_times_section h2,
.contact_us_availability_container,
.contact_us_address_container,
.contact_us_contact_container {
  font-size: calc(0.5rem + 2vw);
}

@media only screen and (min-width: 375px) {
  .contact_us_header h1 {
    font-size: 3vh;
  }
}

/* CSS for Tablets in Portrait Mode */
@media only screen and (min-width: 768px) {
  .contact_us_page_container {
    grid-template-rows: 10% 10% 30% 20% 15% 5% 20%;
    grid-template-columns: repeat(8, 12.5%);
    grid-template-areas:
      ". . header header header . . ."
      ". address address address contact contact contact ."
      ". hours hours hours open open open ."
      "map map map map map map map map"
      "map map map map map map map map"
      ". . . . . . . ."
      "footer footer footer footer footer footer footer footer";
    grid-gap: 1rem;
  }

  .contact_us_open_status {
    padding-top: 0vh;
  }

  .contact_us_address_container {
    padding-top: 0vh;
  }

  .contact_us_times_section h2 {
    padding-top: 0vh;
    padding-bottom: 3vh;
  }

  .contact_us_header h1 {
    font-size: 4vh;
    padding-bottom: 3vh;
  }

  .contact_us_times_section h2,
  .contact_us_availability_container,
  .contact_us_open_status h3,
  .contact_us_open_status p,
  .contact_us_times_section h2,
  .contact_us_availability_container,
  .contact_us_address_container,
  .contact_us_contact_container {
    font-size: 1.5vh;
  }

  .contact_us_open_status,
  .contact_us_address_container,
  .contact_us_contact_container,
  .contact_us_times_section {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media only screen and (min-width: 850px) {
  .contact_us_header h1 {
    font-size: 5vh;
    padding-bottom: 4vh;
  }
}

/* CSS for Larger Tablets in Portrait Mode */
@media only screen and (min-width: 1024px) {
  .contact_us_header h1 {
    font-size: 5vh;
    padding-bottom: 4vh;
  }
}

@media only screen and (min-width: 1200px) {
  .contact_us_page_container {
    grid-template-rows: 10% 15% 35% 20% 25% 5% 15%;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      ". . . header header . . ."
      ". address address . . contact contact ."
      ". hours hours . . open open ."
      "map map map map map map map map"
      "map map map map map map map map"
      ". . . . . . . ."
      "footer footer footer footer footer footer footer footer";
  }

  .contact_us_header h1 {
    font-size: calc(0.5rem + 3vh);
    padding-bottom: calc(0.5rem + 3vh);
  }

  .contact_us_times_section h2,
  .contact_us_availability_container,
  .contact_us_open_status h3,
  .contact_us_open_status p,
  .contact_us_times_section h2,
  .contact_us_availability_container,
  .contact_us_address_container,
  .contact_us_contact_container {
    font-size: 2vh;
  }
}

/* CSS for Big Desktops */
@media only screen and (min-width: 1800px) {
  .contact_us_map_container {
    width: 100%;
    margin-left: 3.6rem;
  }

  .contact_us_page_container {
    grid-template-rows: 15% 20% 20% 20% 10% 1fr;
    grid-template-columns: none;
    grid-template-areas:
      "header header header header header header header header header"
      "map map map map map  . address contact contact"
      "map map map map map . hours open open"
      "map map map map map . hours open open"
      ". . . . . . . . ."
      "footer footer footer footer footer footer footer footer footer";
  }

  .contact_us_header,
  .contact_us_underline {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
  }

  .contact_us_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 10vh;
  }

  .contact_us_header h1 {
    font-size: calc(1rem + 4.5vh);
    padding-bottom: calc(1rem + 3.5vh);
  }

  .contact_us_times_section h2,
  .contact_us_availability_container,
  .contact_us_open_status h3,
  .contact_us_open_status p,
  .contact_us_times_section h2,
  .contact_us_availability_container,
  .contact_us_address_container,
  .contact_us_contact_container {
    font-size: 3vh;
  }
}

/* CSS for Huge Desktops */
@media only screen and (min-width: 2000px) {
  .contact_us_header h1 {
    font-size: calc(1rem + 4.5vh);
    padding-bottom: calc(1rem + 4.5vh);
  }
}
