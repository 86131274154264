/** =================== MONTSERRAT =================== **/

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("./MontserratFont/woff2/Montserrat-Light.woff2") format("woff2"),
    url("./MontserratFont/woff/Montserrat-Light.woff") format("woff"),
    url("./MontserratFont/ttf/Montserrat-Light.ttf") format("truetype"),
    url("./MontserratFont/otf/Montserrat-Light.otf") format("opentype");
  font-display: swap;
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("./MontserratFont/woff2/Montserrat-Regular.woff2") format("woff2"),
    url("./MontserratFont/woff/Montserrat-Regular.woff") format("woff"),
    url("./MontserratFont/ttf/Montserrat-Regular.ttf") format("truetype"),
    url("./MontserratFont/otf/Montserrat-Regular.otf") format("opentype");
  font-display: swap;
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("./MontserratFont/woff2/Montserrat-Medium.woff2") format("woff2"),
    url("./MontserratFont/woff/Montserrat-Medium.woff") format("woff"),
    url("./MontserratFont/ttf/Montserrat-Medium.ttf") format("truetype"),
    url("./MontserratFont/otf/Montserrat-Medium.otf") format("opentype");
  font-display: swap;
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("./MontserratFont/woff2/Montserrat-SemiBold.woff2") format("woff2"),
    url("./MontserratFont/woff/Montserrat-SemiBold.woff") format("woff"),
    url("./MontserratFont/ttf/Montserrat-SemiBold.ttf") format("truetype"),
    url("./MontserratFont/otf/Montserrat-SemiBold.otf") format("opentype");
  font-display: swap;
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("./MontserratFont/woff2/Montserrat-Bold.woff2") format("woff2"),
    url("./MontserratFont/woff/Montserrat-Bold.woff") format("woff"),
    url("./MontserratFont/ttf/Montserrat-Bold.ttf") format("truetype"),
    url("./MontserratFont/otf/Montserrat-Bold.otf") format("opentype");
  font-display: swap;
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("./MontserratFont/woff2/Montserrat-Black.woff2") format("woff2"),
    url("./MontserratFont/woff/Montserrat-Black.woff") format("woff"),
    url("./MontserratFont/ttf/Montserrat-Black.ttf") format("truetype"),
    url("./MontserratFont/otf/Montserrat-Black.otf") format("opentype");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
}

html {
  height: 100%;
}

body {
  -webkit-text-size-adjust: none;
  max-width: 100vw;
  overflow-x: hidden;
}

.height_hundred_percent {
  height: 100%;
}

.height_remove_hundred_percent {
  height: auto;
}

.reset_scroll_behavior {
  scroll-behavior: auto;
}

.no_scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.no_scroll_no_fixed {
  overflow: hidden;
  width: 100%;
}

.scroll_reset {
  overflow: scroll;
  overflow-x: hidden;
  position: static;
}

.main_container {
  height: 100vh;
  overflow: auto;
  /* Important for Smooth Momentum Scrolling */
  -webkit-overflow-scrolling: touch;
}

/* For temporarily locking in portrait mode on mobile */
.wrapper-prevent-orientation {
  height: 200vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper-prevent-orientation p {
  top: 18% !important;
}

@media (prefers-reduced-motion: reduce) {
  html {
    --scroll-behavior: auto;
    scroll-behavior: auto;
  }
}

.unsure_toast_container,
.toast_container {
  background-color: rgb(214, 236, 252);
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
}

.toast_removed_container {
  background-color: rgb(199, 221, 237);
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
}

.toast_error_container {
  background-color: rgb(225, 225, 225);
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
}

.log_out_modal {
  -webkit-box-shadow: -1px 2px 20px 4px rgba(99, 99, 99, 1);
  -moz-box-shadow: -1px 2px 20px 4px rgba(99, 99, 99, 1);
  box-shadow: -1px 2px 20px 4px rgba(99, 99, 99, 1);
  position: fixed;
  z-index: 5;
  height: 45%;
  margin-top: -10%;
  border-radius: 10px;
  width: 80vw;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(244, 239, 243);
}

.log_out_modal_contents {
  display: flex;
  height: 70%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
}

.log_out_modal_contents h2 {
  font-size: 1.4rem;
  font-weight: 600;
}

.logout_buttons_container {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.cancel_logout_button,
.logout_button {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 30px;
  color: white;
}

.logout_button {
  background: rgb(44, 44, 52);
  cursor: pointer;
}

.cancel_logout_button {
  border: 2px solid rgb(44, 44, 52);
  color: rgb(44, 44, 52);
  cursor: pointer;
}

.modal_x {
  position: absolute;
  top: 3%;
  right: 7%;
  color: rgb(0, 129, 177);
  font-size: 2rem;
  cursor: pointer;
}

.ReactModalPortal {
  position: fixed;
  z-index: 99999999 !important;
}

.clip_loader_spinner_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: rgba(211, 211, 211, 0.8);
  backdrop-filter: blur(8px);
  z-index: 999999;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.react-cookie-banner {
  display: flex;
  align-items: center;
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100px !important;
  z-index: 500 !important;
  font-family: "Montserrat" !important;
  background: 20px 50% / 30px 30px no-repeat rgb(50, 50, 50) !important;
  background-image: url(./images/cookie.png) !important;
  -webkit-filter: invert(1);
  filter: invert(1);
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  animation: fadeIn 0.5s ease;
}

.react-cookie-banner > .button-close {
  border: 1px solid white !important;
  border-radius: 4;
  width: 66px !important;
  height: 32px !important;
  line-height: 32px !important;
  background: transparent !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  opacity: 1;
  right: 20px !important;
  margin-top: -18px !important;
}

.cookie-message {
  line-height: 1.3 !important;
  font-weight: 500 !important;
  color: white;
  display: block !important;
  padding: 9px 67px;
  text-align: left;
  margin-right: 30px !important;
}

.cookie-message > p {
  text-decoration: none !important;
  margin-left: 0 !important;
}

.cookie-message > p > a {
  color: white !important;
  text-decoration: underline !important;
}

::-webkit-scrollbar {
  height: 0px; /* height of horizontal scrollbar ← You're missing this */
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-thumb {
  background: rgb(44, 44, 52);
  border-radius: 10px;
}

@media only screen and (max-width: 360px) {
  .react-cookie-banner {
    height: 60px !important;
    font-size: 7px !important;
  }
}

@media only screen and (max-width: 1800px) and (orientation: portrait),
  only screen and (max-width: 1200px) {
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

/* CSS for Tablet Portrait / Mobile Landscape */
@media only screen and (min-width: 600px) {
  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 42vw;
    margin-left: -5.8rem;
  }
}

/* CSS for Tablets in Portrait Mode */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .react-cookie-banner {
    font-size: 0.8rem !important;
  }

  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 100vw;
    margin-left: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .Toastify__toast-container--bottom-center {
    width: 100vw;
    right: 0;
    margin: 0 auto;
  }
}

/* CSS for laptops and regular-sized desktops */
@media only screen and (min-width: 1200px) and (orientation: portrait) {
  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 100vw;
    margin-left: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .Toastify__toast-container--bottom-center {
    width: 100vw;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

/* CSS for laptops and regular-sized desktops */
@media only screen and (min-width: 1200px) and (orientation: landscape) {
  /* Prevent larger browser scrollbar view */
  html,
  body {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  body::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 28.5vw;
    margin-left: -5rem;
  }

  .react-cookie-banner {
    font-size: 0.9rem !important;
    top: 0 !important;
    z-index: 999999999999 !important;
  }

  .cookie-message {
    font-weight: 400 !important;
  }
}

@media only screen and (min-width: 1200px) {
  .log_out_modal {
    width: 35vw;
    height: 35%;
  }
}

@media only screen and (min-width: 1200px) and (max-height: 950px) {
  .log_out_modal {
    height: 50vh;
    margin-top: 10vh;
  }
}

/* CSS for Medium Laptops */
@media only screen and (min-width: 1400px) {
  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 29vw;
    margin-left: -8.3rem;
  }
}

/* CSS for Regular-Sized Desktops */
@media only screen and (min-width: 1600px) {
  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 29.4vw;
    margin-left: -11.75rem;
    border: 1px solid rgb(211, 211, 211);
  }
}

/* CSS for Big Desktops */
@media only screen and (min-width: 1800px) {
  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 30vw;
    margin-left: -18.4rem;
    border: 1px solid rgb(211, 211, 211);
  }

  .log_out_modal {
    height: 45%;
    margin-top: 0%;
    width: 25vw;
  }

  .log_out_modal_contents h2 {
    font-size: 2rem;
    width: 70%;
  }

  .cancel_logout_button,
  .logout_button {
    font-size: 1.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

/* CSS for Huge Desktops */
@media only screen and (min-width: 2200px) {
  .toast_error_container,
  .toast_container,
  .toast_removed_container {
    width: 30.8vw;
    margin-left: -31.6rem;
    border: 1px solid rgb(211, 211, 211);
  }

  .log_out_modal {
    height: 45%;
    margin-top: 0%;
    width: 25vw;
  }

  .log_out_modal_contents h2 {
    font-size: 2rem;
    width: 60%;
  }

  .cancel_logout_button,
  .logout_button {
    font-size: 1.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
