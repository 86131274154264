/* CSS for Tiny Portrait Screens (e.g. iPhone 5, Galaxy Note 3) */
@media only screen and (min-width: 300px) {
  .burger {
    background: transparent;
    display: flex;
    flex-direction: column;
    height: 2.5rem;
    width: 30%;
    border: none;
    cursor: pointer;
    flex: 1;
  }

  .burger_line {
    display: flex;
    height: 4px;
    width: 1.8rem;
    transition: all 0.5s ease;
    position: relative;
    z-index: 99999;
  }

  .nav_logo_container {
    position: fixed;
    left: 3rem;
    padding-bottom: 1.2rem;
  }

  .logo {
    display: flex;
    flex: 1;
    margin-left: 0.6rem;
    margin-bottom: 0.2rem;
    padding: 0;
    color: rgb(239, 240, 243);
    text-decoration: none;
    height: 100%;
    overflow: hidden;
  }

  .spacer {
    flex: 40;
  }

  .nav_sign_in_button_small_screen {
    display: block;
    position: absolute;
    margin-right: 2.8rem;
    right: 0.5rem;
    font-weight: 600;
    font-size: 0.7rem;
    border: 1px solid rgb(239, 240, 243);
    color: rgb(44, 44, 52);
    background: rgb(239, 240, 243);
    padding: 0.3rem;
    padding-right: 1rem;
    border-radius: 20px;
  }

  .nav_sign_in_link_container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nav_sign_in_button_small_screen p {
    display: inline-block;
    color: rgb(44, 44, 52);
  }

  .sign_in_user_icon {
    display: inline-block;
    color: rgb(44, 44, 52);
  }

  .sign_in_circle_icon {
    font-size: 1.2rem;
    display: inline-block;
    color: rgb(44, 44, 52);
  }

  .letter_circle {
    position: relative;
    left: 0.8rem;
    right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fa-layers.fa-fw.letter_circle p {
    color: rgb(239, 240, 243);
    position: relative;
    z-index: 2;
    text-align: center;
    margin-left: 0;
    font-size: 0.8rem;
    font-weight: 700;
  }

  .shopping_cart_small_screen {
    margin-right: 1.5rem;
    position: absolute;
    right: -0.5rem;
    left: auto;
  }

  .cart_item_number_circle {
    position: absolute;
    right: 0.8rem;
  }

  .cart_item_number_circle p {
    color: rgb(44, 44, 52);
    font-weight: 700;
    font-size: 0.8rem;
    position: absolute;
    top: 0.1rem;
    right: 0.05rem;
  }

  .nav_menu {
    margin-top: 8vh;
    height: 92vh;
    width: 100%;
    position: fixed;
    z-index: 5;
  }

  .navbar_items {
    height: 46vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
  }

  .navbar_items li {
    display: flex;
    text-decoration: none;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    color: rgb(239, 240, 243);
    cursor: pointer;
  }

  .navbar_items li:active {
    color: rgb(239, 240, 243);
    transition: color 0.1s ease;
  }

  .nav_links {
    display: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .navbar_social_media_icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-self: flex-start;
  }

  .navbar_social_media_icons a {
    margin-left: 1rem;
    color: rgb(239, 240, 243);
  }

  .nav_bar_profile_picture_thumbnail {
    border-radius: 50%;
    height: 2vh;
    width: 2vh;
    margin-top: 0.3vh;
    margin-right: 6vw;
  }
}

/* CSS for Small Portrait Screens (e.g. iPhone 6/7/8, iPhone X) */
@media only screen and (min-width: 360px) {
  .burger {
    height: 3rem;
    width: 100%;
  }

  .cart_item_number_circle {
    top: 0.9rem;
  }

  .nav_sign_in_button_small_screen {
    margin-right: 3.2rem;
    font-size: 1rem;
  }

  .navbar_items li {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
}

/* Additional CSS for Mobile Phones with Smaller Heights like Galaxy Note 9 */
@media only screen and (min-width: 360px) and (max-device-height: 700px) {
  .shopping_cart_small_screen {
    margin-top: 0.2rem;
  }

  .cart_item_number_circle {
    margin-top: 0.2rem;
  }

  @supports not (-webkit-overflow-scrolling: touch) {
    /* CSS for other than iOS devices */
    .shopping_cart_small_screen {
      margin-top: 0rem;
    }

    .cart_item_number_circle {
      margin-top: 0rem;
    }

    .navbar_items li {
      font-size: 1.5rem;
      margin-bottom: 2.5rem;
    }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .navbar_items li {
      font-size: 2.5rem;
      margin-top: 2rem;
    }
  }
}

/* Additional CSS for Mobile Phones with Smaller Heights like Galaxy Note 9 */
@media only screen and (min-width: 360px) and (min-device-height: 701px) {
  .shopping_cart_small_screen {
    margin-top: 0.4rem;
  }

  .cart_item_number_circle {
    margin-top: 0.4rem;
  }
}

/* Additional CSS for Mobile Phones with Smaller Heights */
@media only screen and (min-width: 375px) and (max-device-height: 800px) {
  .shopping_cart_small_screen {
    margin-top: -0.5rem;
  }

  .cart_item_number_circle {
    margin-top: -0.5rem;
  }
}

/* Additional CSS for Mobile Phones with Slightly Larger Heights */
@media only screen and (min-width: 375px) and (min-device-height: 801px) {
  .shopping_cart_small_screen {
    margin-top: 0rem;
  }

  .cart_item_number_circle {
    margin-top: 0rem;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .shopping_cart_small_screen {
      margin-top: -0.3rem;
    }

    .cart_item_number_circle {
      margin-top: -0.3rem;
    }
  }
}

/* CSS for Average Portrait Screens (e.g. iPhone 11) */
@media only screen and (min-width: 410px) {
  .shopping_cart_small_screen {
    margin-top: 0.1rem;
  }

  .cart_item_number_circle {
    top: 0.7rem;
    right: 1rem;
  }
}

/* Additional CSS for iPhones 6+, 6s+, 7+, 8+ */
@media only screen and (min-width: 410px) and (min-device-height: 700px) and (max-device-height: 800px) {
  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */

    .navbar_items li {
      font-size: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 6rem;
    }
  }
}

/* Additional CSS for Mobile Phones with Smaller Heights */
@media only screen and (min-width: 410px) and (max-device-height: 800px) {
  .shopping_cart_small_screen {
    margin-top: -0.2rem;
  }

  .cart_item_number_circle {
    margin-top: -0.3rem;
  }
}

/* iPhone 11 */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  @supports (-webkit-overflow-scrolling: touch) {
    .cart_item_number_circle {
      margin-top: -0.1rem;
    }
  }
}

/* iPhone XS Max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  @supports (-webkit-overflow-scrolling: touch) {
    .cart_item_number_circle {
      margin-top: -0.1rem;
    }
  }
}

/* Google Pixel 4XL */
@media screen and (device-width: 480px) and (device-height: 1014px) {
  @supports not (-webkit-overflow-scrolling: touch) {
    /* CSS for other than iOS devices */
    .shopping_cart_small_screen {
      margin-top: 0.4rem;
    }

    .navbar_items li {
      font-size: 3rem;
      margin-bottom: 4rem;
    }
  }
}

/* CSS for Tablet / Mobile Landscape */
@media only screen and (min-width: 600px) {
  .nav_logo_container {
    left: 3.6rem;
  }

  .logo {
    margin-left: 3.1rem;
  }

  .nav_logo_container {
    transform: translate(0%, 20%);
  }

  .nav_sign_in_button_small_screen {
    margin-right: 6rem;
  }

  .nav_menu {
    margin-top: 8vh;
    height: 100%;
    width: 105vw;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .navbar_items {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }

  .navbar_items li {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  .shopping_cart_small_screen {
    position: absolute;
  }

  .cart_item_number_circle {
    right: 2.5rem;
  }

  .nav_bar_profile_picture_thumbnail {
    height: 2vh;
    width: 2vh;
    margin-right: 5vw;
  }
}

/* CSS for Tablets in Portrait Mode */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .navbar {
    z-index: 999999;
  }

  .nav_logo_container {
    max-height: 8vh;
  }

  .shopping_cart_small_screen {
    margin-top: 0rem;
    transform: scale(1.5) translate(-15%, 30%);
  }

  .burger_line {
    height: 5px;
    width: 3rem;
  }

  .nav_menu {
    margin-top: 8vh;
  }

  .navbar_items li {
    font-size: 4rem;
    margin-bottom: 7rem;
  }

  .nav_logo_container {
    transform: scale(1.8) translate(10%, 10%);
  }

  .nav_sign_in_button_small_screen {
    margin-right: 6.5rem;
    font-size: 1.5rem;
    padding-right: 1rem;
    text-align: center;
  }

  .cart_item_number_circle {
    transform: scale(1.5) translate(0rem, 0vh);
  }

  .nav_bar_profile_picture_thumbnail {
    height: 2vh;
    width: 2vh;
    margin-top: 0.25vh;
    margin-right: 4vw;
  }
}

/* CSS for Tablets in Portrait Mode */
@media only screen and (min-width: 768px) and (min-device-height: 1000px) and (max-device-height: 1300px) {
  .nav_logo_container {
    transform: scale(1.8) translate(10%, -999%);
  }
}

/* CSS for Larger Tablets in Portrait Mode */
@media only screen and (min-width: 1001px) {
  .nav_logo_container {
    transform: scale(1.8) translate(10%, -20%);
  }
}

/* CSS for Larger Tablets in Portrait Mode */
@media only screen and (min-width: 1024px) and (max-device-height: 1400px) {
  .nav_logo_container {
    transform: scale(1.8) translate(10%, -19%);
  }

  .nav_sign_in_button_small_screen {
    margin-right: 8rem;
    font-size: 1.8rem;
  }

  .shopping_cart_small_screen {
    transform: scale(1.5) translate(-15%, 40%);
  }

  .cart_item_number_circle {
    transform: scale(1.5) translate(-1vh, 0.5vh);
  }
}

/* CSS for laptops and regular-sized desktops */
@media only screen and (min-width: 1200px) {
  .logo {
    margin-left: 3.1rem;
  }

  .nav_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .navbar_items {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }

  .navbar_items li {
    font-size: 4rem;
    margin-bottom: 7rem;
  }

  .nav_logo_container {
    display: flex;
    position: relative;
    left: 0;
    padding-bottom: 1.5rem;
    transform: scale(1.3) translate(-10%, -2%);
  }

  .cart_item_number_circle {
    transform: scale(1) translate(0vh, 0vh);
  }

  .nav_sign_in_button_small_screen {
    display: none;
  }

  .navbar {
    height: 8vh;
  }

  .burger,
  .nav_menu {
    display: none;
  }

  .nav_links {
    width: 50%;
    display: flex;
  }

  .nav_links ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
  }

  .nav_links li {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style: none;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
  }

  .nav_bar_hover_link_container p:hover {
    color: rgb(191, 191, 191);
    transition: all 0.5s ease;
  }

  .shopping_cart_small_screen {
    display: none;
  }

  .nav_sign_in_button_large_screen {
    display: flex;
    left: 0.4rem;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 30px;
    margin-top: -0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
  }

  .nav_sign_in_button_large_screen:hover {
    background: rgb(0, 0, 0);
    transition: all 0.5s ease;
  }

  .nav_sign_in_button_large_screen p {
    display: inline-block;
    margin-left: 0.5rem;
  }

  .letter_circle {
    top: 0.5rem;
  }

  .sign_in_user_icon {
    display: inline-block;
    margin-right: 0.2rem;
  }

  .shopping_cart_large_screen {
    width: 80%;
    position: relative;
    font-size: 5rem;
  }

  .nav_bar_profile_picture_thumbnail {
    height: 2vh;
    width: 2vh;
    margin-top: 0.2vh;
    margin-right: 2vw;
  }

  .shopping_cart_large_screen:hover {
    fill: rgb(255, 255, 255);
    stroke: rgb(255, 255, 255);
    transition: all 0.5s ease;
  }

  .cart_item_number_circle {
    right: 2.6rem;
    margin-top: 0rem;
  }

  .cart_item_number_circle p {
    top: 0rem;
    left: 0.2rem;
  }
}

@media only screen and (min-width: 1200px) and (max-height: 950px) {
  .nav_bar_profile_picture_thumbnail {
    height: 2.5vh;
    width: 2.5vh;
  }
}

/* CSS for Slightly Smaller Medium Laptops (e.g. Chromebook) */
@media only screen and (min-width: 1360px) and (max-width: 1399px) and (max-device-height: 800px) {
  .nav_sign_in_button_large_screen {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
}

/* CSS for Medium-Sized Laptop */
@media only screen and (min-width: 1400px) {
  .nav_links li {
    font-size: 1rem;
  }

  .cart_item_number_circle {
    right: 2.8rem;
    margin-top: 0rem;
  }

  .cart_item_number_circle p {
    top: 0.1rem;
    left: auto;
  }

  .nav_sign_in_button_large_screen {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* CSS for Medium Laptops */
@media only screen and (min-width: 1400px) and (max-width: 1500px) and (max-device-height: 900px) {
  .cart_item_number_circle {
    right: 3rem;
  }
}

/* CSS for Regular-Sized Desktops */
@media only screen and (min-width: 1600px) {
  .nav_logo_container {
    height: 100%;
    padding-bottom: 0rem;
    margin-left: 1.5rem;
  }

  .nav_links ul {
    margin-left: 1.5rem;
  }

  .nav_links li {
    font-size: 0.95rem;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
  }

  .letter_circle {
    top: 0.5rem;
  }

  .cart_item_number_circle {
    right: 2.5rem;
  }

  .logo_svg {
    transform: scale(2);
    position: absolute;
    top: 6vh;
    left: 3.8rem;
  }

  .nav_sign_in_button_large_screen {
    margin-left: 0rem;
    padding: 0.5rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }

  .nav_bar_profile_picture_thumbnail {
    height: 2vh;
    width: 2vh;
    margin-top: 0.1vh;
    margin-right: 6vw;
  }
}

/* CSS for Big Desktops */
@media only screen and (min-width: 1800px) {
  .nav_links li {
    display: flex;
    list-style: none;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .shopping_cart_large_screen {
    right: -9rem;
    margin-top: 1.1rem;
  }

  .cart_item_number_circle {
    margin-top: 0.2rem;
    right: 2.4rem;
  }

  .cart_item_number_circle p {
    font-size: 1rem;
    top: 0.2rem;
    margin-right: 0.1rem;
  }

  .logo_svg {
    left: 4.5rem;
    top: 6.5vh;
  }

  .nav_sign_in_button_large_screen {
    height: 85%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 0.2rem;
    left: 1.5rem;
  }

  .shopping_cart_large_screen {
    right: -1.5rem;
    margin-top: 0.3rem;
  }

  .nav_bar_profile_picture_thumbnail {
    height: 2vh;
    width: 2vh;
    margin-top: 0.1vh;
    margin-right: 5vw;
  }

  .letter_circle {
    top: 0.5rem;
  }

  /* Safari Only */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .cart_item_number_circle p {
        top: 0.1rem;
        margin-right: 0.05rem;
      }
    }
  }
}

/* CSS for Huge Desktops */
@media only screen and (min-width: 2200px) {
  .nav_links li {
    display: flex;
    list-style: none;
    font-size: 2rem;
    font-weight: 500;
  }

  .nav_sign_in_button_large_screen {
    height: 100%;
    left: 1.5rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    margin-top: -0.1rem;
  }

  .nav_sign_in_button_large_screen p {
    font-size: 1.8rem;
  }

  .cart_item_number_circle {
    margin-top: 0.5rem;
    right: 3rem;
  }

  .cart_item_number_circle p {
    font-size: 1.2rem;
    top: 0.4rem;
    margin-right: 0.3rem;
  }

  .logo_svg {
    left: 5rem;
    top: 7vh;
  }

  .sign_in_circle_icon {
    font-size: 2rem;
    display: none;
  }

  .letter_circle {
    top: 0.6rem;
  }

  .fa-layers.fa-fw.letter_circle p {
    font-size: 1.2rem;
  }

  .nav_bar_profile_picture_thumbnail {
    height: 2vh;
    width: 2vh;
    margin-top: 0.3rem;
    margin-right: 2vw;
  }
}
